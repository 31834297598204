export const renderDate = params => {
    const m = new Date(params.value);
    const dateString = m.getUTCFullYear() + "-"
        + (m.getUTCMonth() + 1) + "-"
        + m.getUTCDate() + " "
        + m.getUTCHours() + ":"
        + m.getUTCMinutes() + ":"
        + m.getUTCSeconds();

    return `${dateString}`;
}