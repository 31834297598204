import * as R from 'ramda';
import {DataGrid} from "@mui/x-data-grid";

export default function RecordManager(props) {
    const records = props.records;
    const columns = props.columns;

    const handleGetNextPage = () => {

    }

    const cols = [
        {
            field: 'id',
            headerName: 'ID',
            editable: false,
        },
        {
            field: 'title',
            headerName: 'Title',
            editable: false,
            width: 300,
        },
        {
            field: 'postedOn',
            headerName: 'Posted Approximately',
            editable: false,
            width: 150,
        }
    ]

    function prepData(data) {
        const renameId = o => {
            let n = {...o};
            n['id'] = n['externalId'];
            return n;
        }

        const d = R.map(renameId, data);
        return d;
    }

    return (
        <>
            <h1>Record Manager</h1>

            <div style={{height: 400, width: '100%'}}>
                {records && records.length !== 0
                    ?
                        <DataGrid
                            rows={prepData(records)}
                            columns={cols}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            />
                    :
                        <p>No results</p>
                }
            </div>
        </>
    )
}