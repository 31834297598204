import {createTheme} from "@mui/material/styles";


let theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: "#FFB26A",
        },
        secondary: {
            main: "#FF6A6A"
        }
    },
    typography: {
        fontSize: 16,
        fontFamily: 'Montserrat, Arial',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    },
});

export {theme}