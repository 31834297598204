import {useEffect, useState} from "react";
import {apiGet, apiPost} from "../network";
import SurfPlayer from "../SurfPlayer/SurfPlayer";
import ModalLoader from "../ModalLoader";

const ITEMS_PER_BATCH = 50;
const API_ENDPOINT = '/trailers';

/**
 * The Surf Controller is responsible for delivering video content to the SurfPlayer
 * @returns {JSX.Element}
 * @constructor
 */
export default function SurfController() {

    const [trailers, setTrailers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pageNum, setPageNum] = useState(0);

    document.title = "Surf shows";

    const sendVote = async (mediaId, score) => {
        const body = {
            mediaType: "trailers",
            mediaId: mediaId,
            score: score,
        }

        try {
            await apiPost('/user-history/score', body);
        } catch (error) {
            console.error(error);
        }
    }

    const handleLike = async content => await sendVote(content['externalId'], 1);

    const handleDislike = async content => await sendVote(content['externalId'], -1);

    const handleIndexUpdated = async newIndex => {
        console.info("COntroller got new index", newIndex);
        if (ITEMS_PER_BATCH - newIndex === 0) {
            const newPageNum = pageNum + 1;
            setPageNum(newPageNum);
        }
    };

    const loadPage = async (pg, pageSize) => {
        pageSize = pageSize || ITEMS_PER_BATCH;
        setIsLoading(true);

        const response = await apiGet('/user-history/unscored/trailers', {
            params: {
                limit: pageSize,
                offset: pg * pageSize,
            }
        });
        const content = response.payload;

        setTrailers(content);
        setIsLoading(false);
    }

    useEffect(() => {
        loadPage(pageNum);
    }, [setTrailers, pageNum]);

    return (
        <>
            <ModalLoader open={isLoading}/>
            {!isLoading &&
            <SurfPlayer
                content={trailers}
                loaded={false}
                onLike={handleLike}
                onDislike={handleDislike}
                onIndexUpdated={handleIndexUpdated}
            />
            }
        </>
    )
}