import {useEffect, useState} from "react";
import {apiGet} from "../network";
import {DataGrid} from "@mui/x-data-grid";
import {renderDate} from "./common";


/**
 ** Approach

 1. Retrieve total item count from server
 2. maintain a query state object tracking the page num, page size, and last data loaded
 3. Populate a datatable component
 3. Recreate state as user triggers page change or page size changess

 */


const useQuery = (page, pageSize) => {
    const [rowCount, setRowCount] = useState(undefined);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        let active = true;

        setIsPageLoading(true);
        setRowCount(undefined);

        apiGet('/trailers', {
            params: {
                limit: pageSize,
                offset: -pageSize + (page + 1) * pageSize
            }
        })
            .then((response) => {
                if (!active) {
                    return;
                }
                const items = response.payload;
                const prepped = items.map(x => {
                    let y = {...x};
                    y['id'] = y['externalId'];
                    return y;
                });
                setData(prepped);
                setRowCount(prepped.length);
            })
            .catch(e => console.log(e))
            .finally(() => setIsPageLoading(false));

        return () => {
            active = false;
        }
    }, [page, pageSize]);

    return {isPageLoading, data, rowCount};
}

const useDataSetCount = (datasetName) => {
    const [count, setCount] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let active = true;
        setIsLoading(true);
        setCount(undefined);

        apiGet('/count', {
            params: {tbl: datasetName},
        })
            .then((response) => {
                setCount(response.payload[0].count);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setIsLoading(false));

        return () => {
            active = false;
        }
    }, [datasetName]);

    return {count, isLoading, datasetName};
}

export default function Trailers() {
    const [countLoaded, setCountLoaded] = useState(false);
    const {count, isLoading} = useDataSetCount('trailers');
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 100,
    });

    const {isPageLoading, data, rowCount} = useQuery(rowsState.page, rowsState.pageSize);

    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 480,
        },
        {
            field: 'platform',
            headerName: 'Platform',
        },
        {
            field: 'postedOn',
            headerName: 'Est Publish Date',
            minWidth: 180,
            valueFormatter: (params) => renderDate(params),
        }
    ]

    // Block until a valid count is obtained
    useEffect(() => {
        if (!isLoading && count) {
            setCountLoaded(true);
        }
    }, [count, isLoading]);


    // Some api clients return undefined while loading
    // Following lines are here to prevent `rowCountState` from being undefined during the loading
    const [rowCountState, setRowCountState] = useState(rowCount || 0);
    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    return (
        <div style={{height: 400, width: '100%'}}>
            {countLoaded &&
            <DataGrid
                columns={columns}
                rows={data}
                rowCount={rowCountState}
                loading={isPageLoading}
                rowsPerPageOptions={[100]}
                pagination
                {...rowsState}
                paginationMode="server"
                onPageChange={(page) => setRowsState((prev) => ({...prev, page}))}
                onPageSizeChange={(pageSize) =>
                    setRowsState((prev) => ({...prev, pageSize}))
                }
            />
            }
        </div>
    )
}
