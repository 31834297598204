import MuiContainer from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {useEffect, useState} from "react";
import useAuth from "../useAuth";
import {useNavigate} from "react-router-dom";


function LoginForm() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {isLoading, error, login} = useAuth();
    const navigate = useNavigate();

    function passwordChanged(event) {
        setPassword(event.target.value);
    }

    function emailChanged(event) {
        setEmail(event.target.value);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        setIsSubmitting(isLoading);
        try {
            await login(email, password);
            navigate("/");
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <form onSubmit={handleSubmit} className="LoginForm">
            <Stack direction="column" spacing={2} alignItems="center">
                <TextField
                    id="email"
                    className="theme-fs"
                    label="Email"
                    variant="outlined"
                    autoComplete="email"
                    onChange={emailChanged}
                />
                <TextField
                    id="password"
                    className="theme-fs"
                    label="Password"
                    variant="outlined"
                    type="password"
                    autoComplete="current-password"
                    onChange={passwordChanged}
                />

                {error &&
                <Alert severity="error">{error}</Alert>
                }

                {isSubmitting
                    ? <CircularProgress/>
                    : <Button
                        type="submit"
                        className="signInButton"
                        variant="contained"
                        disabled={!(email && password)}
                    >Sign In</Button>
                }
            </Stack>
        </form>

    )
}


export default function Login() {

    useEffect(() => {
        document.title = "Surfs up!"
    }, []);

    return (
        <MuiContainer sx={{p: 2}} component="main" maxWidth="sm">
            <Paper sx={{p: 4}}>

                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2}}>
                    <Avatar sx={{mt: 1, bgcolor: "secondary.main", width: 48, height: 48}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <h1>Login</h1>
                    <LoginForm />
                </Box>
            </Paper>
        </MuiContainer>
    )
}