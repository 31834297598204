import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useLocation, useNavigate} from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TvIcon from '@mui/icons-material/Tv';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import useAuth from "./useAuth";
import {SizeProvider} from "./useSize";


const drawerWidth = 240;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function MiniDrawer({children}) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar id="AppBar" position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{mr: 2, ...(open && {display: 'none'})}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Surf
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <NavList open={open} />
            </Drawer>
            <Main open={open} sx={{flexGrow: 1, p: 0}}>
                <DrawerHeader/>
                <div style={{position: 'relative'}}>
                    {children}
                </div>
            </Main>
        </Box>
    );
}

function NavList(props) {
    const open = props.open;
    const {logout} = useAuth();

    return (
        <List>
            <NavButton text="Surf" url="/" open={open}><TvIcon/></NavButton>
            <NavButton text="Favorites" url="/favorites" open={open}><FavoriteIcon/></NavButton>
            <NavButton text="Profile" url="/me" open={open}><AccountCircleIcon/></NavButton>
            <Divider/>
            <NavButton text="Trailers" url="/admin/titles" open={open}><LocalMoviesIcon/></NavButton>
            <Divider/>
            <NavButton text="Logout" onClick={logout} open={open}><LogoutIcon/></NavButton>
        </List>
    )

}

function NavButton(props) {
    const text = props.text;
    const open = props.open;
    const children = props.children;
    const url = props.url || null;
    const onClick = props.onClick || Function;

    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = () => {
        if (url) {
            navigate(url);
        } else {
            onClick();
        }
    }

    return (
        <ListItemButton
            key={text}
            onClick={handleClick}
            selected={location.pathname === url}
            sx={{
                minHeight: "2rem",
                justifyContent: open ? "initial" : "center",
                px: 2.5,
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                }}
            >
                {children}
            </ListItemIcon>
            <ListItemText primary={text} sx={{opacity: open ? 1 : 0}}/>
        </ListItemButton>
    )
}


export default function AppLayout({children}) {

    return (
        <SizeProvider>
            <MiniDrawer>
                {children}
            </MiniDrawer>
        </SizeProvider>
    );
}