import axios from "axios";
import {apiPathFor} from "../network";


export class User {
    constructor(id, email) {
        this.id = id;
        this.email = email;
    }
}

export async function getLoginToken(email, password) {
    let path = apiPathFor('/auth/login');
    let payload = {};
    const body = {
        email: email,
        password: password
    }
    try {
        const response = await axios.post(path, body);
        payload = response.data;
    } catch (error) {
        payload = error.response.data;
    }
    return payload;
}
