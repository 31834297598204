import "./SurfPlayer.css";
import MediaPlayer from "./MediaPlayer";
import {useEffect, useState} from "react";
import useSize from "../useSize";
import {
    Typography,
    Container,
    Stack,
    IconButton,
} from "@mui/material";
import {
    createTheme,
    responsiveFontSizes,
    ThemeProvider,
} from '@mui/material/styles';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FavoriteIcon from '@mui/icons-material/Favorite';


let theme = createTheme();
theme = responsiveFontSizes(theme);


export default function SurfPlayer(props) {
    const content = props.content;
    const onLike = props.onLike;
    const onDislike = props.onDislike;
    const onIndexUpdated = props.onIndexUpdated;

    const [index, setIndex] = useState(0);
    const [currentItem, setCurrentItem] = useState(null);
    const [playerHeight, setPlayerHeight] = useState(null);
    const [videoId, setVideoId] = useState(null);
    const {width, height} = useSize();

    useEffect(() => {
        setIndex(0);
    }, [content]);

    useEffect(() => {
        if (content && content[index] && 'trailerUrl' in content[index]) {
            const item = content[index];
            setCurrentItem(item);
            const youTubeUrl = new URL(item.trailerUrl);
            const youTubeId = youTubeUrl.searchParams.get('v');
            setVideoId(youTubeId);
        }
    }, [index, content]);

    useEffect(() => {
        const barHeight = document.getElementById('AppBar').offsetHeight;
        setPlayerHeight(height - barHeight);
    }, [width, height]);

    const handleLike = (event) => {
        onLike(currentItem);
        getNext();
    }

    const handleDislike = (event) => {
        onDislike(currentItem);
        getNext();
    }


    function getNext() {
        // do the stuff, transitions, etc

        // advance...
        const newIndex = index + 1;
        setIndex(newIndex);
        onIndexUpdated(newIndex);
    }


    return (
        <>
            {currentItem &&
            <div
                id="SurfPlayer"
                className="SurfPlayer"
                style={{height: playerHeight}}
            >
                <div className="SurfPlayer--content">

                    <ThemeProvider theme={theme}>

                        <Container className={"SurfPlayer--meta"} sx={{mb: '1rem'}}>

                            <div className="SurfPlayer--platform">
                                <Typography variant={"overline"}>
                                    {currentItem.platform}
                                </Typography>
                            </div>

                            <div className="SurfPlayer--title">
                                <Typography variant={"h4"}>
                                    {currentItem.title}
                                </Typography>
                            </div>

                        </Container>

                    </ThemeProvider>

                    <MediaPlayer videoId={videoId}/>

                    <Container className="SurfPlayer--Controls">
                        <Stack direction="row"
                               spacing={12}
                               sx={{justifyContent: "center"}}
                        >
                            <IconButton
                                aria-label="dislike"
                                name="dislike"
                                onClick={handleDislike}
                            >
                                <DeleteForeverIcon sx={{fontSize: 80}}/>
                            </IconButton>

                            <IconButton
                                aria-label="like"
                                name="like"
                                onClick={handleLike}
                            >
                                <FavoriteIcon sx={{fontSize: 80}}/>
                            </IconButton>
                        </Stack>
                    </Container>

                </div>

                <div
                    className="SurfPlayer--bgFill"
                    style={{backgroundImage: `url(${currentItem.thumbnailUrl})`}}
                />
            </div>
            }

        </>
    )
}