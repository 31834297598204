import {
    Container,
} from "@mui/material";
import "./MediaPlayer.css";
import YouTube from "react-youtube";

export default function MediaPlayer(props) {
    const videoId = props.videoId;

    const opts = {
        width: '100%',
        playerVars: {
            autoplay: 1,
        }
    }

    const onReady = event => {
        event.target.playVideo();
    }

    return (
        <Container className={"SurfPlayer--media"}>
            <YouTube videoId={videoId} opts={opts} onReady={onReady}/>
        </Container>
    )
}