const USER_ID = "REACT_USER_ID";
const CURRENT_USER = "REACT_USER";

export const storageProvider = () => {

    const _container = localStorage;

    const getItem = (name) => {
        return _container.getItem(name);
    }

    const setItem = (name, value) => {
        _container.setItem(name, value);
    }

    const removeItem = (name) => {
        _container.removeItem(name);
    }

    return {
        USER_ID,
        CURRENT_USER,
        getItem,
        setItem,
        removeItem,
    }
}
