import {
    HashRouter,
    Route,
    Routes,
    useLocation,
    Navigate
} from "react-router-dom";
import App from "./App";
import SurfController from "./pages/SurfController";
import Login from "./pages/Login";
import Trailers from "./pages/Trailers";
import React from "react";
import useAuth, {AuthProvider} from "./useAuth";
import UserProfile from "./pages/UserProfile";
import {FavoritesController} from "./pages/Favorites";
import HandleAdminAuth from "./pages/HandleAdminAuth";


function RequireAuth({children}) {
    const auth = useAuth();
    const location = useLocation();

    if (!auth.user) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }
    return children;
}


function RequireAdmin({children}) {
    const {user} = useAuth();
    const location = useLocation();


    if (user.admin === false) {
        return <Navigate to="/" state={{from: location}} replace/>;
    }

    if (user.uri2fa && !user.token2fa) {
        window.location = user.uri2fa;
    }

    return children;
}

export default function AppRoutes() {

    return (
        <AuthProvider>
            <HashRouter>
                <Routes>
                    <Route path="/" element={<App/>}>
                        <Route index element={<RequireAuth><SurfController/></RequireAuth>}/>
                        <Route path="favorites" element={<RequireAuth><FavoritesController/></RequireAuth>}/>
                        <Route path="me" element={<RequireAuth><UserProfile/></RequireAuth>}/>
                        <Route path="/admin/titles" element={<RequireAdmin><Trailers/></RequireAdmin>}/>
                    </Route>
                    <Route path="login" element={<Login/>}/>
                    <Route path="/duo-callback" element={<HandleAdminAuth />} />
                </Routes>
            </HashRouter>
        </AuthProvider>
    )
}