import {Outlet} from "react-router-dom";
import {AuthProvider} from "./useAuth";
import AppLayout from "./AppLayout";

function App() {

    return (
        <AuthProvider>
            <AppLayout>
                <Outlet/>
            </AppLayout>
        </AuthProvider>
    )
}

export default App;
