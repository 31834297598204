import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {getLoginToken} from "./models/user";
import {storageProvider} from "./storageProvider";


const AuthContext = createContext({});

export function AuthProvider({children}) {
    const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    // Attempt to load user from storage
    useEffect( () => {
        async function thawUser() {
            const sp = storageProvider();

            try {
                const curUser = JSON.parse(sp.getItem(sp.CURRENT_USER));
                setUser(curUser);
            } catch (error) {
                console.info("no user data found");
            } finally {
                setIsInitialLoadComplete(true);
            }
        }
        thawUser().then();
    }, []);

    /**
     * Authenticates and loads user object
     * @param email
     * @param password
     * @returns {Promise<void>}
     */
    const login = async (email, password) => {
        setLoading(true);

        if (!user) {
            const loginResult = await getLoginToken(email, password);

            if (loginResult.code === 200) {
                const curUser = loginResult.payload;
                setUser(curUser);
                const sp = storageProvider();
                await sp.setItem(sp.CURRENT_USER, JSON.stringify(curUser));
            } else {
                setError(loginResult.message)
            }
        }

        setLoading(false);
    }

    const logout = async () => {
        const sp = storageProvider();
        await sp.removeItem(sp.CURRENT_USER);
        setUser(undefined);
        window.location.reload();
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    // eslint complains about login missing from dep array, but complains when it's inside
    const memoedValue = useMemo(
        () => ({
            user,
            isLoading,
            error,
            login,
            logout,
        }),
        [user, isLoading, error]
    );
    /* eslint-enable */

    return (
        <AuthContext.Provider value={memoedValue}>
            {isInitialLoadComplete && children}
        </AuthContext.Provider>
    )
}

export default function useAuth() {
    return useContext(AuthContext);
}