import {createContext, useContext, useEffect, useMemo, useState} from "react";

const DEBOUNCE_DELAY_MS = 150;

const getWidth = () => {
    return window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
}

const getHeight = () => {
    return window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
}


const SizeContext = createContext({});

export function SizeProvider({children}) {
    let [width, setWidth] = useState(getWidth());
    let [height, setHeight] = useState(getHeight());

    useEffect(() => {
        let timeoutId = null;

        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                const h = getHeight();
                const w = getWidth();
                setHeight(h);
                setWidth(w);
            }, DEBOUNCE_DELAY_MS);
        };

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }

    }, []);


    const getSize = useMemo(
        () => ({
            width,
            height,
        }),
        [width, height]
    );

    return (
        <SizeContext.Provider value={getSize}>
            {children}
        </SizeContext.Provider>
    )
}

export default function useSize() {
    return useContext(SizeContext);
}