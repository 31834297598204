import {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import MuiContainer from "@mui/material/Container";
import {CircularProgress} from "@mui/material";
import {apiGet, fetchRecords} from "../network";
import RecordManager from "../components/RecordManager";

const API_ENDPOINT = '/user-history/scored/trailers';

export function FavoritesController(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        async function loadFavorites() {
            let response;

            setIsLoading(true);

            try {
                response = await apiGet(API_ENDPOINT, {
                    params: {
                        limit: limit,
                        offset: offset,
                    }
                });
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }

            setRecords(response.payload);

        }

        loadFavorites();

    }, [])

    return (
        <MuiContainer sx={{p: 2}} component="main" maxWidth="lg">
            <Paper sx={{p: 4}}>
                {isLoading
                    ? <CircularProgress/>
                    : <RecordManager records={records} />
                }
            </Paper>
        </MuiContainer>
    )
}