import axios from "axios";
import {storageProvider} from "./storageProvider";

export const apiPathFor = path => process.env.REACT_APP_API_HOST + path;

/**
 * Thaws user record
 * @returns {any}
 */
const getUser = () => {
    const sp = storageProvider();
    return JSON.parse(sp.getItem(sp.CURRENT_USER));
}

/**
 * Generates a pre-loaded config object for making a request on
 * behalf of an authenticed user
 * @returns {{headers: {Authorization: string}}}
 */
const authHeader = () => {
    const user = getUser();
    if (user && user.token) {
        return {
            headers: {'Authorization': 'Bearer ' + user.token}
        }
    }
}


/**
 * Merges authorization details with an authorization header
 * @param config
 * @returns {{headers: {Authorization: string}}}
 */
const authConfig = (config) => {
    let cfg = authHeader();
    if (config && cfg) cfg = {...cfg, ...config};
    return cfg;
}

/**
 * Makes an GET request to the SURF api
 * @param path Surf API endpoint
 * @param config a configuration object passed to the network library
 * @returns Object api response object
 */
export async function apiGet(path, config) {
    const cfg = authConfig(config);
    const url = apiPathFor(path);

    let payload = {};

    try {
        const response = await axios.get(url, cfg);
        payload = response.data;
    } catch (error) {
        payload = error.response.data;
    }
    return payload;
}

/**
 * Makes an GET request to the SURF api
 * @param path Surf API endpoint
 * @param data dictionary of values
 * @param config a configuration object passed to the network library
 * @returns Object api response object
 */
export async function apiPost(path, data, config) {
    const cfg = authConfig(config);
    const url = apiPathFor(path);

    let payload = {};

    try {
        const response = await axios.post(url, data, cfg);
        payload = response.data;
    } catch (error) {
        payload = error.response.data;
    }
    return payload;
}
